<template>
  <div class="signature-form-dialog">
    <jha-form-signature-input
      @change="handleDataURLchange"
      @touchend="handleDataURLchange"
      :class="{ 'signature-state-input-height': currentState === signatureState }"
    >
    </jha-form-signature-input>
    <footer class="footer">
      <jha-button type="button" :disabled="!isValidImage" @click="onSaveSignature">{{
        BltSignatureField.SAVE
      }}</jha-button>
    </footer>
  </div>
</template>

<script lang="ts">
import "@banno/jha-wc/src/forms/jha-form-signature-input/jha-form-signature-input.js";
import { defineComponent } from "vue";
import { BltSignatureField } from "@/common/constant/bltSignatureFieldConstant";
import { $injByInterface } from "@/common/decorators/depinject";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import isObject from 'lodash.isobject'
export default defineComponent({
  name: "bltSignatureFormDialog",
  emits: ["signatureChanged"],
  setup() {
    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    return {
      workspaceStore
    };
  },
  data() {
    return {
      BltSignatureField,
      signatureDataImage: '',
      currentState: null,
      signatureState: WorkflowStatesConstant.SIGNATURE.STATE,
      WorkflowStatesConstant
    };
  },
  created() {
    this.currentState = this.workspaceStore.workflow.currentState.state;
  },
  computed: {
    isValidImage() {
      return (this.signatureDataImage || "").length > 0;
    }
  },
  methods: {
    handleDataURLchange($event: CustomEvent<{image: string}>) {
      // todo(mikol): for some reason the jha component is emitting two events on each change
      //
      // Event A: 0
      // Event B: { image '=base64Image' }
      //
      // Eventually we should fix this upstream, but we need a workaround to get our 1.35 release out.
      if(isObject($event.detail)) {
        this.signatureDataImage = $event.detail.image;
      }
    },
    onSaveSignature() {
      this.$emit("signatureChanged", {
        detail: this.signatureDataImage
      });
    }
  }
});
</script>

<style scoped>
.signature-form-dialog {
  display: block;
  width: 94%;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: var(--jh-space-400);
  padding-bottom: var(--jh-space-200);
}
jha-form-signature-input {
  --jha-form-signature-height: 200px;
  background: transparent;
  --jha-color-primary: var(--link-button-text-color);
}

.signature-state-input-height {
  --jha-form-signature-height: 200px;
}
.footer jha-button {
  width: 120px;
}


</style>
