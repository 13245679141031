import isUndefined from 'lodash.isundefined';
import EnrollmentAccountFactory from "@/common/services/Enrollment/enrollmentAccountFactory";
import ApplicantFactory from "@/common/services/Applicant/applicantFactory";
import EnrollmentApplicantFactory from "@/common/services/Enrollment/enrollmentApplicantFactory";
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from 'inversify';
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from '@/common/enums/responseTypesEnum';
import type IAccount from "@/common/models/IAccount";

@injectable()
class EnrollmentAccountApplicantFactory {
    constructor(
        @inject(EnrollmentAccountFactory) private enrollmentAccountFactory: EnrollmentAccountFactory,
        @inject(ApplicantFactory) private applicantFactory: ApplicantFactory,
        @inject(EnrollmentApplicantFactory) private enrollmentApplicantFactory: EnrollmentApplicantFactory,
        @inject(HttpHandler) private httpHandler: HttpHandler
    ) { }

    deleteAccountApplicant(workspaceUUID: string, enrollmentId: number, accountId: number, applicantId: number) {
        return this.httpHandler.delete(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/applicant/${applicantId}`, {}, ResponseTypes.Data);
    }

    async deleteAllAccountApplicant(workspaceUUID: string, enrollmentId: number, enrollmentAccount: any) {
        return Promise.all(enrollmentAccount.applicants.map((applicant: any) => {
            return this.deleteAccountApplicant(workspaceUUID, enrollmentId, enrollmentAccount.accountId, applicant.applicantId);
        })).then(() => {
            enrollmentAccount.applicants = [];
            return enrollmentAccount;
        });
    }

    putAccountApplicant(workspaceUUID: string, enrollmentId: number, account: any, applicant: any) {
        return this.httpHandler.put(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${account.accountId}/applicant/`, {
            accountId: account.accountId,
            applicants: [applicant],
            primaryApplicantId: account.primaryApplicantId,
        }, {}, ResponseTypes.Payload);
    }


    postAccountApplicant(workspaceUUID: string, enrollmentId: number, accountId: number, applicant: any) {
        return this.httpHandler.post(`${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/applicant/`, {
            accountId: accountId,
            applicants: [applicant],
        }, {}, ResponseTypes.Payload);
    }

    async linkAccountApplicantsNotOnAccount(workspaceUUID: string, enrollmentId: number, account: any, applicantList: any[]) {
        const applicantsToLink = applicantList.filter((applicant) => {
            return !account.applicants.some((accountApplicant: any) => {
                return accountApplicant.applicantId === applicant.applicantId;
            });
        });
        return Promise.all(applicantsToLink.map((applicant) => {
            return this.postAccountApplicant(workspaceUUID, enrollmentId, account.accountId, applicant);
        }));
    }

    linkAllApplicantsToAllAccounts(workspaceUUID: string, enrollmentId: number) {
        return Promise.all([
            this.enrollmentAccountFactory.getEnrollmentAccountList(workspaceUUID, enrollmentId),
            this.applicantFactory.getApplicants(workspaceUUID),
        ])
            .then((response: any) => {
                return Promise.all(response[0].map((account: any) => {
                    return this.linkAccountApplicantsNotOnAccount(workspaceUUID, enrollmentId, account, response[1]);
                }));
            });
    }

    accountContainsApplicant(account: any, applicantId: number) {
        if (isUndefined(account.applicants) || !Array.isArray(account.applicants)) {
            return false;
        }
        return account.applicants.reduce((ret: number, applicant: any) => {
            return (applicant.applicantId == applicantId || ret);
        }, false);
    }

    accountContainsAnyApplicant(account: any) {
        if (isUndefined(account.applicants) || !Array.isArray(account.applicants)) {
            return false;
        }
        return account.applicants.length > 0;
    }

    applicantContainsRole(applicant: any, roleCode: any) {
        if (!Array.isArray(applicant.roles)) {
            return false;
        }
        return applicant.roles.reduce((ret: string, role: string) => {
            return (role == roleCode || ret);
        }, false);
    }

    async deleteFromAllAccounts(workspaceUUID: string, enrollmentId: number, applicantId: number) {
        return await this.enrollmentAccountFactory.getEnrollmentAccountList(workspaceUUID, enrollmentId)
            .then(async (response: Array<IAccount>) => {
                const accountsContainingApplicant = response.filter((account: IAccount) => {
                    return this.accountContainsApplicant(account, applicantId);
                });

                await Promise.all(accountsContainingApplicant.map((account: IAccount) => {
                    return this.deleteAccountApplicant(workspaceUUID, enrollmentId, account.accountId, applicantId);
                }));

            });
    }

}
export default EnrollmentAccountApplicantFactory;